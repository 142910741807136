import { Component } from '@zento-lib/components';

import { FooterNewsletter } from '../../../../molecule/Footer/Newsletter/Newsletter';
import { FooterBlocks } from '../../../../molecule/Footer/Blocks/Blocks';
import { CmsBlock } from '../../../../molecule/CmsBlock/CmsBlock';
import { FooterSocialLinks } from '../../../../atom/Footer/SocialLinks/SocialLinks';
import { ANPC } from '../../../../atom/Footer/ANPC/ANPC';
import { FooterAbsolute } from '../../../../atom/Footer/Absolute/Absolute';
import { Accordion } from '../../../../atom/Accordion/Accordion';
import { AccordionItem } from '../../../../atom/Accordion/Item';
import { Footer } from '../../Footer';

import style from './style.scss';

/**
 * Footer1
 *
 * Renders blocks information (like newsletter, social media, important links)
 */
@Component({})
export class Footer4 extends Footer {
  render() {
    return (
      <footer class={style.footer}>
        <div class={style.topFooter} key='top-footer'>
          <div class={style.container}>
            <CmsBlock identifier={this.$data.footerConfig.topCmsBlockId} />
            <div class={style.newsletterWrapper}>
              <FooterNewsletter
                newsletterBtnLabel={{ id: Footer.T.btn }}
                inputLabel={{ id: Footer.T.newsletterInputLabel }}
                inputPlaceHolder={{ id: Footer.T.newsletterInputPlaceholder }}
                checkboxLabel={{ id: Footer.T.newsletterCheckboxPlaceholder }}
                key='footer-newsletter'
              />
            </div>
            {!this.$data.footerConfig.socialLinksBottom ? (
              <div class={style.socialLinksWrapper}>
                <FooterSocialLinks
                  socialLinks={this.$data.footerConfig.socialLinks}
                  facebookLabel={{ id: Footer.T.facebook }}
                  instagramLabel={{ id: Footer.T.instagram }}
                  youtubeLabel={{ id: Footer.T.youtube }}
                  key='footer-social-links'
                />
              </div>
            ) : null}
          </div>
        </div>
        <div class={style.middleFooter} key='middle-footer'>
          <Accordion activeTab={this.$data.footerConfig.activeTab} class={style.footerAccordion}>
            {this.navigationStore.footerNavigation.map((page) => {
              return (
                <AccordionItem class={style.footerAccordionItem} index={page.id}>
                  <h3 slot='accordionTrigger'>{this.getTranslation(page.label)}</h3>
                  <FooterBlocks content={page.children_data} slot='accordionContent' />;
                </AccordionItem>
              );
            })}
          </Accordion>
        </div>

        <ANPC />

        <div class={style.bottomFooter}>
          <FooterAbsolute
            copyright={
              '© ' + new Date().getFullYear() + ' ' + this.extended.$config.zento.theme.storeData.storeName + '.'
            }
            allRightsReserved={{ id: Footer.T.allRightsReserved }}
            poweredBy={{ id: Footer.T.poweredBy }}
            poweredByLink={{ id: Footer.T.poweredByLink }}
            poweredByLinkLabel={{ id: Footer.T.poweredByLinkLabel }}
            key='footer-absolute'
          />
        </div>
      </footer>
    );
  }
}
